import { Image, Text, View } from '@react-pdf/renderer';
import PageTemplate, {
  pdfPagePadding,
} from '@rsa-digital/evo-shared-components/components/pdf/components/PageTemplate';
import RichText from '@rsa-digital/evo-shared-components/components/pdf/components/RichText';
import { paragraphTextStyle } from '@rsa-digital/evo-shared-components/components/pdf/components/typography';
import generatePdf from '@rsa-digital/evo-shared-components/components/pdf/generatePdf';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import React from 'react';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import AggregatorSection, { AggregatorSectionProps } from './AggregatorSection';
import DeclarationSection, { DeclarationSectionProps } from './DeclarationSection';
import DetailsSection, { DetailsSectionProps } from './DetailsSection';
import DocumentsSection, { DocumentsSectionProps } from './DocumentsSection';
import KeepingYouInformedSection, {
  KeepingYouInformedSectionProps,
} from './KeepingYouInformedSection';
import PricingOverviewSection, {
  PricingOverviewSectionProps,
} from './PricingOverviewSection';
import SummaryOfNeedsSection, {
  SummaryOfNeedsSectionProps,
} from './SummaryOfNeedsSection';
import { Heading1 } from './typography';

type Props = {
  quote: CurrentQuote;
  heroProps: {
    heading: string;
    subheading?: string;
    logoUrl?: string;
  };
  editInfoProps: {
    editInfoText: string;
  };
  detailsSectionProps: DetailsSectionProps;
  documentSectionProps: DocumentsSectionProps;
  declarationSectionProps: DeclarationSectionProps;
  pricingOverviewSectionProps: PricingOverviewSectionProps;
  summaryOfNeedsSectionProps: SummaryOfNeedsSectionProps;
  aggregatorSectionProps: AggregatorSectionProps | undefined;
  keepingYouInformedSectionProps: KeepingYouInformedSectionProps | undefined;
  theme: Theme;
};

const Pdf: React.FC<Props> = ({
  detailsSectionProps,
  documentSectionProps,
  declarationSectionProps,
  pricingOverviewSectionProps,
  summaryOfNeedsSectionProps,
  aggregatorSectionProps,
  keepingYouInformedSectionProps,
  heroProps,
  editInfoProps,
  theme,
}) => (
  <PageTemplate theme={theme}>
    <View
      style={[
        {
          margin: -pdfPagePadding,
          marginBottom: 32 - pdfPagePadding,
          backgroundColor: theme.colors.neutral08,
          color: theme.colors.core01,
          width: '100vw',
          paddingHorizontal: pdfPagePadding,
          paddingVertical: 16,
        },
        paragraphTextStyle,
      ]}>
      {heroProps.logoUrl && (
        <View style={{ height: 32, width: 184 }}>
          <Image src={heroProps.logoUrl} />
        </View>
      )}
      <Heading1>{heroProps.heading}</Heading1>
      <Text>{heroProps.subheading}</Text>
      <RichText html={editInfoProps.editInfoText} theme={theme} />
    </View>
    {aggregatorSectionProps && <AggregatorSection {...aggregatorSectionProps} />}
    <DetailsSection {...detailsSectionProps} />
    <PricingOverviewSection {...pricingOverviewSectionProps} />
    <DeclarationSection {...declarationSectionProps} />
    {keepingYouInformedSectionProps && (
      <KeepingYouInformedSection {...keepingYouInformedSectionProps} />
    )}
    <DocumentsSection {...documentSectionProps} />
    <SummaryOfNeedsSection {...summaryOfNeedsSectionProps} />
  </PageTemplate>
);

export default Pdf;

export const generateCheckYourDetailsPdf = async (props: Props): Promise<void> =>
  generatePdf(<Pdf {...props} />, 'Quote');
